<template>
  <Doorwash />
</template>

<script>
import Doorwash from "../../../components/hotel_doorwash";

export default {
  name: "hotel_doorwash",
  components: {
    Doorwash,
  },
};
</script>